import React from "react"
import { graphql } from "gatsby"
import ReactPlayer from "react-player"
import PropTypes from "prop-types"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Layout } from "../components/layout"
import { DividerBlock } from "../components/dividerBlock"

const Impact = ({ data }) => {
  const { impact } = data

  return (
    <Layout
      bgcolor={impact.backgroundColor}
      wrapperClass="impact"
      accentColor={impact.accentColor}
    >
      <HelmetDatoCms seo={impact.seoMetaTags} />
      <div className="impact-intro container row">
        <div className="col-sm-7 offset-sm-2 col-xs-6 col">
          <h1 className="gothic--large large-title">{impact.title}</h1>
          {impact.featuredVideo && (
            <div className="video-wrapper">
              <ReactPlayer
                url={impact.featuredVideo.url}
                height="100%"
                width="100%"
                controls
                muted
                loop
                title={impact.featuredVideo.title}
                className="responsive-iframe"
              />
            </div>
          )}
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: impact.description,
            }}
          />
        </div>
      </div>
      <div className="divider container row">
        <div className="col-sm-5 col-xs-4 offset-xs-2 offset-sm-3 col">
          <DividerBlock accentColor={impact.accentColor} />
        </div>
      </div>
    </Layout>
  )
}

Impact.propTypes = {
  data: PropTypes.objectOf(PropTypes.shape()).isRequired,
}

export default Impact

export const query = graphql`
  query impactQuery {
    impact: datoCmsImpact {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      backgroundColor
      accentColor {
        hex
      }
      title
      featuredVideo {
        url
        providerUid
        title
      }
      description
    }
  }
`
